<template>
  <div class="container">
    <div class="row">
      <div class="col s12 left-align">
        <h3>
          Clasificación De Niza - 11 edición, versión 2021
        </h3>
        <h3>
          <span id="Prodotti-Classi-Nizza">Lista de clases</span>
        </h3>
        <h4>Clase 1</h4>
        <ul class="browser-default">
          <li>
            El papel sensible;
          </li>
          <li>
            Los compuestos para la reparación de neumáticos;
          </li>
          <li>
            La sal de conservación que no sea para conservar alimentos;
          </li>
          <li>
            Ciertos aditivos destinados a la industria alimentaria, por ejemplo: la pectina, la lecitina, las enzimas y los conservadores químicos;
          </li>
          <li>
            Ciertos ingredientes utilizados en la fabricación de productos cosméticos y productos farmacéuticos, por ejemplo: las vitaminas, los conservadores y los antioxidantes;
          </li>
          <li>
            Ciertas materias filtrantes, por ejemplo: las sustancias minerales, las sustancias vegetales y los materiales cerámicos en partículas.
          </li>
        </ul>
        <h4>Clase 2</h4>
        <ul class="browser-default">
          <li>Las pinturas, los barnices y las lacas para la industria, la artesanía y el arte;</li>
          <li>Los diluyentes, los espesantes, los fijadores y los secantes para pinturas, barnices y lacas;</li>
          <li>Los mordientes para la madera y el cuero;</li>
          <li>Los aceites antiherrumbre y los aceites para conservar la madera;</li>
          <li>Los tintes para prendas de vestir;</li>
          <li>Los colorantes para alimentos y bebidas.</li>
        </ul>
        <h4>Clase 3</h4>
        <ul class="browser-default">
          <li>Las preparaciones de higiene en cuanto productos de tocador;</li>
          <li>Las toallitas impregnadas de lociones cosméticas;</li>
          <li>Los desodorantes para personas o animales;</li>
          <li>Las preparaciones para perfumar el ambiente;</li>
          <li>Las pegatinas decorativas para uñas;</li>
          <li>El papel de lija.</li>
          <li></li>
        </ul>
        <h4>Clase 4</h4>
        <ul class="browser-default">
          <li>Los aceites para conservar obras de albañilería y el cuero;</li>
          <li>La cera en bruto, la cera para uso industrial;</li>
          <li>La energía eléctrica;</li>
          <li>Los carburantes para motores, los biocarburantes;</li>
          <li>Los aditivos no químicos para carburantes;</li>
          <li>La madera utilizada como combustible.</li>
        </ul>
        <h4>Clase 5</h4>
        <ul class="browser-default">
          <li>Los productos de higiene personal que no sean de tocador;</li>
          <li>Los pañales para bebés y para la incontinencia</li>
          <li>Los desodorantes que no sean para personas o animales;</li>
          <li>Los champús, los jabones, las lociones y los dentífricos medicinales;</li>
          <li>Los suplementos alimenticios destinados a completar una dieta normal o a beneficiar la salud;</li>
          <li>Los sustitutos de comidas y los alimentos y bebidas dietéticos para uso médico o veterinario.</li>
        </ul>
        <h4>Clase 6</h4>
        <ul class="browser-default">
          <li>Los metales en hojas o en polvo para el procesamiento ulterior, por ejemplo: para impresoras 3D;</li>
          <li>Los materiales de construcción metálicos, por ejemplo: los materiales metálicos para vías férreas, los tubos y tuberías metálicos;</li>
          <li>Los pequeños artículos de ferretería metálicos, por ejemplo: los pernos, los tornillos, los clavos, las ruedas para muebles, los cierres para ventanas;</li>
          <li>Las construcciones y estructuras transportables metálicas, por ejemplo: las casas prefabricadas, las piscinas, las jaulas para animales salvajes, las pistas de patinaje;</li>
          <li>Ciertos productos de metales comunes no clasificados según su función o finalidad, por ejemplo: las cajas multiuso de metales comunes, las estatuas, los bustos y las obras de arte de metales comunes.</li>
        </ul>
        <h4>Clase 7</h4>
        <ul class="browser-default">
          <li>Las partes de todo tipo de motores, por ejemplo: los arranques, los silenciadores y los cilindros para motores de todo tipo;</li>
          <li>Los aparatos eléctricos de limpieza y de pulido, por ejemplo: las lustradoras de calzado eléctricas, las máquinas eléctricas para lavar alfombras y moquetas y las aspiradoras;</li>
          <li>Las impresoras 3D;</li>
          <li>Los robots industriales;</li>
          <li>Ciertos vehículos especiales no destinados al transporte, por ejemplo: las barredoras, las máquinas para construir carreteras, los buldóceres, los quitanieves, así como los trenes de rodamiento de caucho en cuanto partes de orugas de dichos vehículos.</li>
        </ul>
        <h4>Clase 8</h4>
        <ul class="browser-default">
          <li>Las herramientas que funcionan manualmente para la agricultura, la jardinería y el paisajismo;</li>
          <li>Las herramientas que funcionan manualmente para carpinteros, artistas y otros artesanos, por ejemplo: los martillos, los cinceles y los buriles;</li>
          <li>Los mangos para herramientas de mano que funcionan manualmente, tales como los cuchillos y las guadañas;</li>
          <li>Los instrumentos de mano eléctricos y no eléctricos de aseo personal y arte corporal, por ejemplo: las maquinillas de afeitar, los aparatos para rizar el cabello, los aparatos de tatuaje, así como los aparatos de manicura y pedicura;</li>
          <li>Las bombas que funcionan manualmente;</li>
          <li>Los cubiertos, tales como los cuchillos, los tenedores y las cucharas, incluidos los de metales preciosos.</li>
        </ul>
        <h4>Clase 9</h4>
        <ul class="browser-default">
          <li>Los aparatos e instrumentos de investigación científica para laboratorios;</li>
          <li>Los equipos y simuladores de entrenamiento, por ejemplo: los maniquís para ejercicios de reanimación, los simuladores de conducción y control de vehículos;</li>
          <li>Los aparatos e instrumentos de control y vigilancia de aeronaves, de vehículos náuticos y de vehículos no tripulados, por ejemplo: los instrumentos de navegación, los transmisores, los compases de medición, los aparatos de GPS (sistema mundial de determinación de la posición), los aparatos de conducción automática para vehículos;</li>
          <li>Los aparatos e instrumentos de salvamento y seguridad, por ejemplo: las redes de seguridad, las luces de señalización, los semáforos, las autobombas contra incendios, las alarmas sonoras, las fichas de seguridad en cuanto dispositivos de cifrado;</li>
          <li>Las prendas de vestir que protegen contra lesiones graves o potencialmente mortales, por ejemplo: la ropa de protección contra los accidentes, las radiaciones y el fuego, la ropa antibalas, los cascos de protección, los protectores de cabeza para deportes, los protectores bucales para deportes, los trajes especiales de protección para aviadores, las rodilleras para trabajadores;</li>
          <li>Los aparatos e instrumentos ópticos, por ejemplo: las gafas, las lentes de contacto, las lupas, los espejos de inspección para obras, las mirillas; los imanes;</li>
          <li>Los relojes inteligentes, los monitores de actividad física ponibles;</li>
          <li>Las palancas de mando (joysticks) de ordenador, que no sean para videojuegos, los cascos de realidad virtual, las gafas inteligentes;</li>
          <li>Los estuches para gafas, los estuches para teléfonos inteligentes, los estuches especiales para aparatos e instrumentos fotográficos;</li>
          <li>Los cajeros automáticos, las máquinas facturadoras, los instrumentos y máquinas para ensayos de materiales;</li>
          <li>Las baterías y los cargadores para cigarrillos electrónicos;</li>
          <li>Los dispositivos eléctricos y electrónicos de efectos para instrumentos musicales;</li>
          <li>Los robots de laboratorio, los robots pedagógicos, los robots de vigilancia para la seguridad, los robots humanoides dotados de inteligencia artificial.</li>
        </ul>
        <h4>Clase 10</h4>
        <ul class="browser-default">
          <li>Las vendas de sujeción, las vendas ortopédicas;</li>
          <li>Las prendas especiales para uso médico, por ejemplo: la ropa de compresión, las medias para varices, las camisas de fuerza, el calzado ortopédico;</li>
          <li>Los artículos, instrumentos y dispositivos para la menstruación, la anticoncepción y el parto, por ejemplo: las copas menstruales, los pesarios, los preservativos, los colchones para el parto, los fórceps;</li>
          <li>Los artículos y dispositivos terapéuticos y protésicos de materiales artificiales o sintéticos para implantación, por ejemplo: los implantes quirúrgicos compuestos de materiales artificiales, las prótesis mamarias, los marcapasos cerebrales, los implantes biodegradables de fijación ósea;</li>
          <li>Los muebles especiales para uso médico, por ejemplo: los sillones para uso médico o dental, los colchones de aire para uso médico, las mesas de operaciones.</li>
        </ul>
        <h4>Clase 11</h4>
        <ul class="browser-default">
          <li>Los aparatos e instalaciones de aire acondicionado;</li>
          <li>Los hornos que no sean de laboratorio, por ejemplo: los hornos dentales, los hornos de microondas, los hornos de panadería;</li>
          <li>Las estufas en cuanto aparatos de calefacción;</li>
          <li>Los colectores solares térmicos;</li>
          <li>Los humeros de chimenea, los tiros de chimenea, las chimeneas, las chimeneas para viviendas;</li>
          <li>Los esterilizadores, los incineradores;</li>
          <li>Los aparatos e instalaciones de alumbrado, por ejemplo: los tubos luminosos de alumbrado, los proyectores de luz, los números luminosos para casas, los reflectores para vehículos, las luces para vehículos;</li>
          <li>Las lámparas (aparatos de iluminación), por ejemplo: las lámparas eléctricas, las lámparas de gas, las lámparas de laboratorio, las lámparas de aceite, las farolas, las lámparas de seguridad;</li>
          <li>Los aparatos de bronceado;</li>
          <li>Las instalaciones de baño, los aparatos para baños;</li>
          <li>Los inodoros, los urinarios;</li>
          <li>Las fuentes, las fuentes de chocolate;</li>
          <li>Los cojines, las almohadillas y las mantas electrotérmicas que no sean para uso médico;</li>
          <li>Las bolsas de agua caliente;</li>
          <li>Las prendas de vestir electrotérmicas;</li>
          <li>Las yogurteras eléctricas, las máquinas para hacer pan, las cafeteras, las máquinas para preparar helados cremosos;</li>
          <li>Los aparatos y máquinas de hielo.</li>
        </ul>
        <h4>Clase 12</h4>
        <ul class="browser-default">
          <li>Los motores para vehículos terrestres;</li>
          <li>Los acoplamientos y elementos de transmisión para vehículos terrestres;</li>
          <li>Los aerodeslizadores;</li>
          <li>Los vehículos teledirigidos que no sean juguetes;</li>
          <li>Las partes de vehículos, por ejemplo: los parachoques, los parabrisas, los volantes, los neumáticos para ruedas de vehículos, así como las orugas para vehículos.</li>
        </ul>
        <h4>Clase 13</h4>
        <ul class="browser-default">
          <li>Las luces de bengala de emergencia explosivas o pirotécnicas;</li>
          <li>Las pistolas de bengalas;</li>
          <li>Las señales de niebla explosivas, los cohetes de señales;</li>
          <li>Las pistolas de aire comprimido en cuanto armas;</li>
          <li>Las bandoleras para armas;</li>
          <li>Las armas de fuego para cazar.</li>
        </ul>
        <h4>Clase 14</h4>
        <ul class="browser-default">
          <li>Los artículos de joyería, incluidos los artículos de bisutería, por ejemplo: el estrás;</li>
          <li>Los gemelos de camisa, los alfileres de corbata, los pasadores de corbata;</li>
          <li>Los llaveros y los dijes para llaveros;</li>
          <li>Los joyeros;</li>
          <li>Las partes constitutivas de artículos de joyería y relojería, por ejemplo: los cierres y las perlas para joyería, los movimientos de relojería, las agujas de reloj, los resortes de reloj, los cristales de reloj.</li>
        </ul>
        <h4>Clase 15</h4>
        <ul class="browser-default">
          <li>Los instrumentos musicales mecánicos y sus accesorios, por ejemplo: los organillos, los pianos mecánicos, los reguladores de intensidad para pianos mecánicos, las baterías robóticas;</li>
          <li>Las cajas de música;</li>
          <li>Los instrumentos musicales eléctricos y electrónicos;</li>
          <li>Las cuerdas, las lengüetas, las clavijas y los pedales para instrumentos musicales;</li>
          <li>Los diapasones, los afinadores de cuerdas;</li>
          <li>La colofonia para instrumentos musicales de cuerda.</li>
        </ul>
        <h4>Clase 16</h4>
        <ul class="browser-default">
          <li>Los cortapapeles;</li>
          <li>Los estuches, fundas y dispositivos para contener o proteger artículos de papel, por ejemplo: las carpetas para documentos, las pinzas para billetes, las fundas para talonarios de cheques, las pinzas para sujetar papeles, las fundas para pasaportes, los álbumes;</li>
          <li>Ciertas máquinas de oficina, por ejemplo: las máquinas de escribir, las multicopistas, las máquinas franqueadoras de oficina, las máquinas sacapuntas;</li>
          <li>Los artículos de pintura para artistas, así como para pintores de interiores y exteriores, por ejemplo: las salseras para pintura, los caballetes y paletas para pintores, los rodillos y bandejas para pintura;</li>
          <li>Ciertos productos desechables de papel, por ejemplo: los baberos, los pañuelos y las mantelerías de papel;</li>
          <li>Ciertos productos de papel o de cartón no clasificados en otras clases según su función o finalidad, por ejemplo: las bolsas, sobres y recipientes de papel para empaquetar, las estatuas, figuritas y objetos de arte de papel o cartón, tales como las figuritas de papel maché, las litografías, los cuadros o las acuarelas, enmarcados o no.</li>
        </ul>
        <h4>Clase 17</h4>
        <ul class="browser-default">
          <li>La goma para recauchutar neumáticos;</li>
          <li>Las barreras flotantes anticontaminación;</li>
          <li>Las cintas adhesivas que no sean de uso médico o doméstico, ni de papelería;</li>
          <li>Las películas de materias plásticas que no sean para empaquetar y acondicionar, por ejemplo: las películas antideslumbrantes para cristales;</li>
          <li>Los hilos elásticos y los hilos de caucho o de materias plásticas que no sean para uso textil;</li>
          <li>Ciertos productos fabricados con materiales comprendidos en esta clase, no clasificados en otras clases según su función o finalidad, por ejemplo: los soportes de gomaespuma para arreglos florales, las materias de acolchado de caucho o de materias plásticas, los tapones de caucho, los tampones amortiguadores de caucho, las bolsas o sobres de caucho para empaquetar.</li>
        </ul>
        <h4>Clase 18</h4>
        <ul class="browser-default">
          <li>Los artículos de equipaje y las bolsas de transporte, por ejemplo: las maletas, los baúles, las bolsas de viaje, las bandoleras portabebés, las mochilas escolares;</li>
          <li>Las etiquetas identificadoras para artículos de equipaje;</li>
          <li>Los tarjeteros y las billeteras;</li>
          <li>Las cajas y cajones de cuero y de cartón-cuero.</li>
        </ul>
        <h4>Clase 19</h4>
        <ul class="browser-default">
          <li>Las maderas semielaboradas para la construcción, por ejemplo: las vigas, los tablones, los paneles;</li>
          <li>Los chapados de madera;</li>
          <li>El vidrio de construcción, por ejemplo: las tejas de vidrio, el vidrio aislante para la construcción, el vidrio armado;</li>
          <li>Las microesferas de vidrio para la señalización horizontal de carreteras;
            el granito, el mármol, la grava;
          </li>
          <li>El barro cocido en cuanto material de construcción;</li>
          <li>Los techados no metálicos con células fotovoltaicas integradas;</li>
          <li>Las lápidas sepulcrales y las tumbas no metálicas;</li>
          <li>Las estatuas, los bustos y las obras de arte de piedra, hormigón o mármol;
            los buzones de correo de obra;
          </li>
          <li>Los geotextiles;</li>
          <li>Los enlucidos en cuanto materiales de construcción;</li>
          <li>Los andamios no metálicos;</li>
          <li>Las construcciones y estructuras transportables no metálicas, por ejemplo: los acuarios, las pajareras, las astas de bandera, las marquesinas, las piscinas.</li>
        </ul>
        <h4>Clase 20</h4>
        <ul class="browser-default">
          <li>Los muebles metálicos y los muebles de camping, los armeros para fusiles, los expositores para periódicos;</li>
          <li>Las persianas de interior para ventanas;</li>
          <li>Los accesorios de cama, por ejemplo: los colchones, los somieres, las almohadas;</li>
          <li>Los espejos de mobiliario y de tocador;</li>
          <li>Las placas de matriculación no metálicas;</li>
          <li>Los pequeños artículos de ferretería no metálicos, por ejemplo: los pernos, los tornillos, las clavijas, las ruedas para muebles, las abrazaderas para tuberías;</li>
          <li>Los buzones de correo no metálicos ni de obra.</li>
        </ul>
        <h4>Clase 21</h4>
        <ul class="browser-default">
          <li>Los utensilios de cocina y los utensilios para uso doméstico, por ejemplo: los matamoscas, las pinzas para la ropa, las cucharas para mezclar, los cucharones y los sacacorchos, así como los utensilios de servir, por ejemplo: las pinzas para el azúcar, las pinzas para el hielo, las palas para tartas y los cucharones de servir;</li>
          <li>Los recipientes para uso doméstico o culinario, por ejemplo: los jarrones, las botellas, las huchas de cerdito, los cubos, las cocteleras, las ollas y cazuelas, así como los hervidores y las ollas a presión no eléctricos;</li>
          <li>Los aparatos pequeños de cocina para picar, moler, exprimir o triturar, que funcionan manualmente, por ejemplo: los prensaajos, los cascanueces, los pilones y los morteros;</li>
          <li>Los salvamanteles, posabotellas y posavasos;</li>
          <li>Los utensilios de tocador y utensilios cosméticos, por ejemplo: los peines y cepillos de dientes eléctricos o no, el hilo dental, los separadores interdigitales de espuma para la pedicura, las borlas de polvera, los neceseres de tocador;</li>
          <li>Los artículos de jardinería, por ejemplo: los guantes de jardinería, las jardineras para ventanas, las regaderas y las boquillas para mangueras de riego;</li>
          <li>Los acuarios de interior, los terrarios de interior y los vivarios de interior.</li>
        </ul>
        <h4>Clase 22</h4>
        <ul class="browser-default">
          <li>Las cuerdas y cordeles de fibras textiles naturales o artificiales, de papel o de materias plásticas;</li>
          <li>Las redes de pescar, las hamacas, las escalas de cuerda;</li>
          <li>Las fundas no ajustables para vehículos;</li>
          <li>Ciertas bolsas y sacos no clasificados en otras clases según su función o finalidad, por ejemplo: las bolsas de malla para lavar la ropa, las bolsas para cadáveres, las sacas postales;</li>
          <li>Las bolsas de materias textiles para empaquetar;</li>
          <li>Las fibras de origen animal y las fibras textiles en bruto, por ejemplo: la crin, los capullos de gusanos de seda, la tela de yute, la lana tratada o en bruto, la seda en bruto.</li>
        </ul>
        <h4>Clase 23</h4>
        <ul class="browser-default">
          <li>Las fibras de vidrio, elásticas, de caucho o de materias plásticas, para uso textil;</li>
          <li>Los hilos de bordar, zurcir o coser, incluidos los hilos metálicos;</li>
          <li>La seda hilada, el algodón hilado, la lana hilada.</li>
        </ul>
        <h4>Clase 24</h4>
        <ul class="browser-default">
          <li>La ropa de hogar, por ejemplo: las colchas, las fundas de almohada, las toallas de materias textiles;</li>
          <li>La ropa de cama de papel;</li>
          <li>Los sacos de dormir, las sábanas para sacos de dormir;</li>
          <li>Los mosquiteros.</li>
        </ul>
        <h4>Clase 25</h4>
        <ul class="browser-default">
          <li>Las partes de prendas de vestir, de calzado y de artículos de sombrerería, por ejemplo: los puños, los bolsillos, los forros confeccionados, los tacones, los refuerzos de talón y las alzas de talón, las viseras para gorras, las armaduras de sombreros;</li>
          <li>Las prendas de vestir y el calzado para deporte, por ejemplo: los guantes de esquí, las camisetas de deporte sin mangas, la ropa para ciclistas, los uniformes de judo y de karate, las botas de fútbol, las zapatillas de gimnasia, las botas de esquí;</li>
          <li>Los trajes de disfraces;</li>
          <li>La ropa de papel, los sombreros de papel en cuanto prendas de vestir;</li>
          <li>Los baberos que no sean de papel;</li>
          <li>Los pañuelos de bolsillo;</li>
          <li>Los folgos que no estén calentados eléctricamente.</li>
        </ul>
        <h4>Clase 26</h4>
        <ul class="browser-default">
          <li>Las pelucas, los tupés, las barbas postizas;</li>
          <li>Los pasadores, las cintas para el cabello;</li>
          <li>Las cintas y los lazos de mercería o para el cabello, de cualquier material;</li>
          <li>Las cintas y los lazos para envolver regalos, que no sean de papel;</li>
          <li>Las redecillas para el cabello;</li>
          <li>Las hebillas, las cremalleras (cierres);</li>
          <li>Los dijes que no sean para artículos de joyería ni llaveros;</li>
          <li>Las guirnaldas y coronas de Navidad artificiales, incluidas las que comprenden luces;</li>
          <li>Ciertos artículos para rizar el cabello, por ejemplo: los bigudís eléctricos o no, las horquillas para ondular el cabello, las tiras de papel para rizar el cabello.</li>
        </ul>
        <h4>Clase 27</h4>
        <ul class="browser-default">
          <li>Las alfombrillas para automóviles;</li>
          <li>Las alfombras para cubrir suelos, por ejemplo: las alfombrillas de baño, los felpudos, las colchonetas de gimnasia, las esteras de yoga;</li>
          <li>El césped artificial;</li>
          <li>El papel pintado, incluido el papel pintado de materias textiles.</li>
        </ul>
        <h4>Clase 28</h4>
        <ul class="browser-default">
          <li>Los aparatos recreativos y de juegos, incluidos sus dispositivos de mando;</li>
          <li>Los artículos de cotillón y los artículos de broma, por ejemplo: las máscaras de carnaval, los sombreros de papel para fiestas, los confetis, los lanzadores de serpentinas y confetis para fiestas y los envoltorios sorpresa de Navidad (Christmas crackers);</li>
          <li>El material de caza y pesca, por ejemplo: las cañas de pescar, los salabardos de pesca, los señuelos, los reclamos de caza;</li>
          <li>Los aparatos para juegos y deportes diversos.</li>
        </ul>
        <h4>Clase 29</h4>
        <ul class="browser-default">
          <li>Los alimentos a base de carne, pescado, fruta o verduras, hortalizas y legumbres;</li>
          <li>Los insectos comestibles;</li>
          <li>Las bebidas lacteadas en las que predomine la leche;</li>
          <li>Los sucedáneos de la leche, por ejemplo: la leche de almendras, la leche de coco, la leche de cacahuete, la leche de arroz, la leche de soja;</li>
          <li>Los champiñones en conserva;</li>
          <li>Las leguminosas y los frutos secos preparados para la alimentación humana;</li>
          <li>Los granos preparados para la alimentación humana, que no sean productos para sazonar o aromatizantes.</li>
        </ul>
        <h4>Clase 30</h4>
        <ul class="browser-default">
          <li>Las bebidas a base de café, cacao, chocolate o té;</li>
          <li>Los cereales preparados para la alimentación humana, por ejemplo: los copos de avena, los chips de maíz, la cebada mondada, el bulgur, el muesli;</li>
          <li>Las pizzas, las tartas saladas, los sándwiches;</li>
          <li>Los frutos secos recubiertos de chocolate;</li>
          <li>Los aromatizantes, que no sean aceites esenciales, alimentarios o para bebidas.</li>
        </ul>
        <h4>Clase 31</h4>
        <ul class="browser-default">
          <li>Los cereales sin procesar;</li>
          <li>Las frutas, verduras, hortalizas y legumbres frescas, incluso lavadas o enceradas;</li>
          <li>Los residuos de origen vegetal;</li>
          <li>Las algas sin procesar;</li>
          <li>La madera sin desbastar;</li>
          <li>Los huevos fertilizados para incubar;</li>
          <li>Las trufas y los champiñones frescos;</li>
          <li>La arena higiénica para animales, por ejemplo: la arena aromática, el papel granulado para lechos de animales de compañía.</li>
        </ul>
        <h4>Clase&nbsp; 32</h4>
        <ul class="browser-default">
          <li>Las bebidas desalcoholizadas;</li>
          <li>Las bebidas refrescantes sin alcohol;</li>
          <li>Las bebidas a base de arroz y de soja, que no sean sucedáneos de la leche;</li>
          <li>Las bebidas energéticas, las bebidas isotónicas, las bebidas enriquecidas con proteínas para deportistas;</li>
          <li>Las esencias y extractos de frutas sin alcohol para elaborar bebidas.•	las esencias y extractos de frutas sin alcohol para elaborar bebidas.</li>
        </ul>
        <h4>Clase 33</h4>
        <ul class="browser-default">
          <li>Los vinos, los vinos generosos;</li>
          <li>Las sidras, la perada;</li>
          <li>Las bebidas espirituosas, los licores;</li>
          <li>Las esencias alcohólicas, los extractos de frutas alcohólicos, los amargos.</li>
        </ul>
        <h4>Clase 34</h4>
        <ul class="browser-default">
          <li>Los sucedáneos del tabaco que no sean para uso médico;</li>
          <li>Los aromatizantes, que no sean aceites esenciales, para cigarrillos electrónicos, los vaporizadores bucales para fumadores;</li>
          <li>Las hierbas para fumar;</li>
          <li>El rapé (tabaco en polvo);</li>
          <li>Ciertos accesorios y recipientes relacionados con el consumo de tabaco y artículos para fumar, por ejemplo: los encendedores para fumadores, los ceniceros para fumadores, los botes para tabaco, las tabaqueras (cajas para rapé), las cajas con humidificador para puros.</li>
        </ul>
        <h4>Clase 35</h4>
        <ul class="browser-default">
          <li>El agrupamiento, por cuenta de terceros, de una amplia gama de productos, excepto su transporte, para que los consumidores puedan verlos y adquirirlos con comodidad; estos servicios pueden ser prestados por comercios minoristas o mayoristas, distribuidores automáticos, catálogos de venta por correspondencia o medios de comunicación electrónicos tales como sitios web o programas de televenta;</li>
          <li>Los servicios de publicidad, de marketing y de promoción, por ejemplo, la distribución de muestras, el desarrollo de conceptos publicitarios, la redacción y publicación de textos publicitarios;</li>
          <li>La decoración de escaparates;</li>
          <li>Los servicios de relaciones públicas;</li>
          <li>La producción de programas de televenta;</li>
          <li>La organización de ferias comerciales y de exposiciones con fines comerciales o publicitarios;</li>
          <li>La optimización de motores de búsqueda con fines de promoción de ventas;</li>
          <li>Los servicios de asistencia comercial, por ejemplo: la selección de personal, la negociación de contratos de negocios para terceros, el análisis del precio de costo, los servicios de agencias de importación-exportación;</li>
          <li>Los servicios administrativos relativos a transacciones de negocios y registros financieros, por ejemplo: la teneduría de libros, la elaboración de estados de cuenta, las auditorías empresariales, las auditorías contables y financieras, la valoración de negocios comerciales, la preparación y la presentación de declaraciones tributarias;</li>
          <li>La gestión comercial de licencias de productos y servicios de terceros;</li>
          <li>Los servicios que comprenden el registro, la transcripción, la composición, la compilación o la sistematización de comunicaciones escritas y grabaciones, así como la compilación de datos matemáticos o estadísticos;</li>
          <li>Los trabajos de oficina, por ejemplo: los servicios de programación y de recordatorio de citas, la búsqueda de datos en archivos informáticos para terceros, los servicios de gestión informática de archivos, los servicios de centrales telefónicas.</li>
        </ul>
        <h4>Clase 36</h4>
        <ul class="browser-default">
          <li>Los servicios de pago y de transacciones financieras, por ejemplo: las operaciones de cambio, la transferencia electrónica de fondos, el procesamiento de pagos por tarjeta de crédito y de débito, la emisión de cheques de viaje;</li>
          <li>La gestión y la investigación financieras;</li>
          <li>Las estimaciones financieras, por ejemplo: la tasación de joyas, obras de arte y bienes inmuebles, la estimación financiera de costos de reparación;</li>
          <li>La verificación de cheques;</li>
          <li>Los servicios de financiación y de crédito, por ejemplo: los préstamos, la emisión de tarjetas de crédito, el arrendamiento con opción de compra;</li>
          <li>La financiación participativa;</li>
          <li>Los servicios de depósito en cajas de seguridad;</li>
          <li>El patrocinio financiero;</li>
          <li>Los servicios de agencias inmobiliarias, la administración de bienes inmuebles, el alquiler de apartamentos, el cobro de alquileres;</li>
          <li>La suscripción de seguros, los servicios actuariales;</li>
          <li>Los servicios de corretaje, por ejemplo: el corretaje de valores, de seguros, y de bienes inmuebles, el corretaje de créditos de carbono, los préstamos prendarios.</li>
        </ul>
        <h4>Clase 37</h4>
        <ul class="browser-default">
          <li>La construcción y la demolición de edificios, carreteras, puentes, presas o líneas de transmisión, así como los servicios en el ámbito de la construcción, por ejemplo: los trabajos de pintura para interiores y exteriores, los trabajos de yesería, los trabajos de fontanería, la instalación de sistemas de calefacción y los trabajos de techado;</li>
          <li>La construcción naval;</li>
          <li>El alquiler de herramientas, de máquinas y equipos de construcción, por ejemplo: el alquiler de buldóceres, el alquiler de grúas;</li>
          <li>Los diversos servicios de reparación, por ejemplo: los relacionados con la electricidad, el hardware, los muebles, los instrumentos y herramientas;</li>
          <li>Los diversos servicios de restauración, por ejemplo: la restauración de edificios, la restauración de muebles, la restauración de obras de arte;</li>
          <li>Los servicios de conservación para mantener el estado original de objetos sin alterar ninguna de sus propiedades;</li>
          <li>La limpieza de diversos objetos, por ejemplo: las ventanas, los vehículos, las prendas de vestir, así como los servicios de lavandería y el planchado de prendas de vestir.</li>
        </ul>
        <h4>Clase 38</h4>
        <ul class="browser-default">
          <li>La transmisión de archivos digitales y de mensajes de correo electrónico;</li>
          <li>El suministro de acceso de usuario a redes informáticas mundiales;</li>
          <li>La radiodifusión y la teledifusión;</li>
          <li>La transmisión de vídeo a la carta;</li>
          <li>El suministro de foros de discusión (chats) en Internet y de foros en línea;</li>
          <li>Los servicios de telefonía y de buzón de voz;</li>
          <li>Los servicios de teleconferencia y de videoconferencia.</li>
        </ul>
        <h4>Clase 39</h4>
        <ul class="browser-default">
          <li>La explotación de estaciones, puentes, ferrocarriles, transbordadores (ferris) y otras instalaciones de transporte;</li>
          <li>El alquiler de vehículos de transporte, así como los servicios de chóferes y de pilotaje;</li>
          <li>Los servicios de alquiler relacionados con el transporte, el almacenamiento y los viajes, por ejemplo: el alquiler de plazas de aparcamiento, el alquiler de garajes, el alquiler de contenedores de almacenamiento;</li>
          <li>La operación de remolcadores marítimos, la descarga de mercancías, la operación de puertos y muelles, así como el salvamento de buques siniestrados y sus cargamentos;</li>
          <li>El empaquetado, el embotellado, el embalaje y el reparto de productos;</li>
          <li>El abastecimiento de distribuidores automáticos y cajeros automáticos bancarios;</li>
          <li>Los servicios de suministro de información sobre viajes o el transporte de mercancías prestados por intermediarios y agencias de turismo, así como los servicios de suministro de información sobre tarifas, horarios y medios de transporte;</li>
          <li>La inspección de vehículos o mercancías para su transporte;</li>
          <li>La distribución de energía y electricidad, así como la distribución y suministro de agua.</li>
        </ul>
        <h4>Clase 40</h4>
        <ul class="browser-default">
          <li>La transformación de un objeto o sustancia y cualquier tratamiento que implique una modificación de sus propiedades esenciales, por ejemplo: el teñido de una prenda de vestir; estos servicios de transformación se clasifican asimismo en la clase 40 si son prestados en el marco de trabajos de reparación o mantenimiento, por ejemplo: el cromado de parachoques de vehículos;</li>
          <li>El tratamiento de materiales que puedan intervenir en el proceso de fabricación de cualquier sustancia u objeto que no sea un edificio, por ejemplo: los servicios en relación con el corte, la conformación, el pulido por abrasión o el revestimiento metálico;</li>
          <li>El ensamblaje de materiales, por ejemplo: los servicios de soldadura;</li>
          <li>El procesamiento y tratamiento de productos alimenticios, por ejemplo: el prensado de frutas, la molienda de grano, la conservación de alimentos y bebidas, el ahumado de alimentos, la congelación de alimentos;</li>
          <li>La fabricación de productos según el pedido y las especificaciones de terceros (teniendo en cuenta que ciertas oficinas exigen que se indiquen los productos fabricados), por ejemplo: la fabricación por encargo de automóviles;</li>
          <li>Los servicios de protésicos dentales;</li>
          <li>El acolchado, los servicios de bordado, los servicios de sastre, el teñido de textiles, el apresto de textiles.</li>
        </ul>
        <h4>Clase 41</h4>
        <ul class="browser-default">
          <li>La organización de exposiciones con fines culturales o educativos, la organización y la dirección de conferencias, congresos y simposios;</li>
          <li>Los servicios de traducción y de interpretación lingüística;</li>
          <li>La publicación de libros y textos, que no sean textos publicitarios;</li>
          <li>Los servicios de reporteros, los reportajes fotográficos;</li>
          <li>Los servicios fotográficos;</li>
          <li>La realización y la producción de películas no publicitarias;</li>
          <li>Los servicios culturales, educativos y recreativos prestados por parques de atracciones, circos, zoos, galerías de arte y museos;</li>
          <li>Los servicios de entrenamiento deportivo y para el mantenimiento físico;</li>
          <li>La doma y el adiestramiento de animales;</li>
          <li>Los servicios de juegos informáticos en línea;</li>
          <li>Los servicios de juegos de apuestas, la organización de loterías;</li>
          <li>La reserva de entradas y los servicios de reserva para eventos educativos, deportivos y de entretenimiento;</li>
          <li>Ciertos servicios de redacción, tales como la redacción de guiones televisivos y cinematográficos, los servicios de compositores y autores de música.</li>
        </ul>
        <h4>Clase 42</h4>
        <ul class="browser-default">
          <li>Los servicios de ingenieros y científicos encargados de efectuar evaluaciones, estimaciones, investigaciones e informes en los ámbitos científico y tecnológico, incluidos los servicios de consultoría tecnológica;</li>
          <li>Los servicios tecnológicos e informáticos sobre la seguridad de los datos informáticos y la información personal y financiera, así como la detección del acceso no autorizado a datos e información, por ejemplo: los servicios de protección antivirus (informática), los servicios de cifrado de datos, la vigilancia electrónica de información de identificación personal para detectar la usurpación de identidad por Internet;</li>
          <li>El software como servicio (SaaS), la plataforma como servicio (PaaS);</li>
          <li>Los servicios de investigación científica con fines médicos;</li>
          <li>Los servicios de arquitectura y de planificación urbana;</li>
          <li>Ciertos servicios de diseño, por ejemplo: el diseño industrial, el diseño de software y de sistemas informáticos, el diseño de interiores, los servicios de diseñadores de embalajes, el diseño de artes gráficas, el diseño de moda;</li>
          <li>Los peritajes (trabajos de ingenieros);</li>
          <li>La prospección de petróleo, gas y minería.</li>
        </ul>
        <h4>Clase 43</h4>
        <ul class="browser-default">
          <li>La reserva de alojamiento temporal, por ejemplo, la reserva de hoteles;</li>
          <li>Los servicios de residencias para animales;</li>
          <li>El alquiler de salas de reunión, tiendas de campaña y construcciones transportables;</li>
          <li>Los servicios de residencias para la tercera edad;</li>
          <li>Los servicios de guarderías infantiles;</li>
          <li>La decoración de alimentos, la escultura de alimentos;</li>
          <li>El alquiler de aparatos de cocción;</li>
          <li>El alquiler de sillas, mesas, mantelería y cristalería;</li>
          <li>Los servicios de bares de shisha;</li>
          <li>Los servicios de chefs de cocina a domicilio.</li>
        </ul>
        <h4>Clase 44</h4>
        <ul class="browser-default">
          <li>Los servicios hospitalarios;</li>
          <li>Los servicios de telemedicina;</li>
          <li>Los servicios de odontología, optometría y salud mental;</li>
          <li>Los servicios de clínicas médicas y los servicios de análisis médicos prestados por laboratorios médicos con fines diagnósticos y terapéuticos, tales como los exámenes radiográficos y las extracciones de sangre;</li>
          <li>Los servicios terapéuticos, por ejemplo: la fisioterapia y logopedia;</li>
          <li>El asesoramiento en materia de farmacia y la preparación de recetas médicas por farmacéuticos;</li>
          <li>Los servicios de bancos de sangre y los servicios de bancos de tejidos humanos;</li>
          <li>Los servicios de casas de convalecencia y de casas de reposo;</li>
          <li>El asesoramiento sobre dietética y nutrición;</li>
          <li>Los servicios de estaciones termales;</li>
          <li>Los servicios de inseminación artificial y de fecundación in vitro;</li>
          <li>La cría de animales;</li>
          <li>El aseo de animales;</li>
          <li>La perforación corporal y los servicios de tatuaje;</li>
          <li>Los servicios en relación con la jardinería, por ejemplo: los servicios de viveros, el diseño de parques y jardines, los servicios de jardineros paisajistas, el mantenimiento del césped;</li>
          <li>Los servicios en relación con el arte floral, por ejemplo: los arreglos florales, la confección de coronas;</li>
          <li>La eliminación de malas hierbas, los servicios de control de plagas para la agricultura, la acuicultura, la horticultura y la silvicultura.</li>
        </ul>
        <h4>Clase 45</h4>
        <ul class="browser-default">
          <li>Los servicios prestados por juristas, asistentes jurídicos y abogados asesores, a personas, grupos de personas, organizaciones o empresas;</li>
          <li>Los servicios de investigación y vigilancia en relación con la seguridad física de bienes materiales y personas;</li>
          <li>Los servicios prestados a personas en relación con acontecimientos sociales, tales como los servicios de acompañamiento en sociedad, los servicios de agencias matrimoniales y los servicios funerarios.</li>
        </ul>
      </div>
    </div>
  </div>

</template>

<script>
export default {

}
</script>

<style>

</style>
